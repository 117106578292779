import React, { useEffect, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";

import * as authActions from "stores/auth/actions";

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import PasswordStrengthBar from "react-password-strength-bar";

import config from "config/global";
import apiDriver from "stores/api.driver";
import { useTranslation } from "react-i18next";
import CountrySelector from "components/AddressForm/CountrySelector";
import PostalCodeInput from "components/AddressForm/PostalCodeInput";
import VatIDInput from "components/AddressForm/VatIDInput";
import useUser from "hooks/useUser";

export function Countries() {
  return [
    {
      code: "pl",
      name: "Polska",
      postalCode: "(([0-9]{2}-[0-9]{3})|([0-9]{5}))",
      phonePrefix: "+48",
      phonePattern:
        "(([0-9]{3}-[0-9]{3}-[0-9]{3})|([0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{2})|([0-9]{3} [0-9]{3} [0-9]{3})|([0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2})|([0-9]{9}))",
    },
  ];
}

function Register() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [signUpProcessed, setSignUpProcessed] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState([]);
  const [isBusiness, setIsBusiness] = useState(false);
  const [vat, setVat] = useState("");
  const [focus, setFocus] = useState("");
  const [registerData, setRegisterData] = React.useState({
    businessName: "",
    user: {
      givenName: "",
      surname: "",
      email: "",
      password: "",
      repeatPassword: "",
    },
    shippingAddress: {
      line1: "",
      line2: "",
      postalCode: "",
      city: "",
      countryCode: "pl",
      person: "",
      email: "",
      phone: "",
    },
    billingInfo: {
      line1: "",
      line2: "",
      postalCode: "",
      city: "",
      countryCode: "pl",
      buyerName: "",
      vatID: "",
      email: "",
      phone: "",
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [error]);

  useEffect(() => {
    setRegisterData({
      ...registerData,
      shippingAddress: {
        ...registerData.shippingAddress,
        email: registerData.user.email
      },
      billingInfo: {
        ...registerData.shippingAddress,
        email: registerData.user.email
      }
    });
  }, [registerData.user.email]);

  useEffect(() => {
    setRegisterData({
      ...registerData,
      shippingAddress: {
        ...registerData.shippingAddress,
        person: [registerData.user.givenName || '', registerData.user.surname || ''].join(' ')
      }
    });
  }, [registerData.user.givenName, registerData.user.surname]);

  useEffect(() => {
    setRegisterData({
      ...registerData,
      billingInfo: {
        ...registerData.billingInfo,
        line1: registerData.shippingAddress.line1
      }
    });
  }, [registerData.shippingAddress.line1]);

  useEffect(() => {
    setRegisterData({
      ...registerData,
      billingInfo: {
        ...registerData.billingInfo,
        line2: registerData.shippingAddress.line2
      }
    });
  }, [registerData.shippingAddress.line2]);

  useEffect(() => {
    setRegisterData({
      ...registerData,
      billingInfo: {
        ...registerData.billingInfo,
        city: registerData.shippingAddress.city
      }
    });
  }, [registerData.shippingAddress.city]);

  useEffect(() => {
    setRegisterData({
      ...registerData,
      billingInfo: {
        ...registerData.billingInfo,
        postalCode: registerData.shippingAddress.postalCode
      }
    });
  }, [registerData.shippingAddress.postalCode]);

  useEffect(() => {
    if (loading.length === 0 && signUpProcessed) {
      dispatch(
        authActions.login(registerData.user.email, registerData.user.password),
      );
    }
  }, [loading, signUpProcessed]);

  const setLoadingProp = (name, value) => {
    if (value) {
      setLoading([...loading, name]);
    } else {
      setLoading([...loading].filter((l) => l !== name));
    }
  };

  const changeDotNotationValue = (entity, notation, value) => {
    if (typeof notation === "string") {
      return changeDotNotationValue(entity, notation.split("."), value);
    }

    if (notation.length === 1) {
      return { ...entity, [notation[0]]: value };
    } else {
      return {
        ...entity,
        [notation[0]]: changeDotNotationValue(
          entity[notation[0]],
          notation.slice(1),
          value,
        ),
      };
    }
  };

  const onChangeRegisterData = (event) => {
    const { name, value } = event.target;
    const newRegisterData = changeDotNotationValue(
      { ...registerData },
      name,
      value,
    );
    setRegisterData(newRegisterData);
  };

  const signUp = () => {
    setLoadingProp("signUp", true);
    setError(null);
    apiDriver
      .post(config.api.iam + "Authentication/SignUp", {
        ...registerData.user,
        culture: i18n.resolvedLanguage || "pl",
        username: registerData.user.email,
      })
      .subscribe({
        next: onSignUp,
        error: (err) => {
          setError(
            t("auth.errors.register.content") + err.response[0].description,
          );
          setLoadingProp("signUp", false);
        },
      });
  };

  const onSignUp = (signUpResponse) => {
    const token = signUpResponse.response.token;
    const headers = {
      Authorization: token ? `Bearer ${token}` : null,
    };

    setLoading(
      isBusiness
        ? ["business", "shippingAddress", "billingInfo"]
        : ["shippingAddress", "billingInfo"],
    );
    setSignUpProcessed(true);

    if (isBusiness) {
      setLoadingProp("business", true);
      apiDriver
        .put(
          `${config.api.orders}${i18n.resolvedLanguage}/Business/`,
          { title: registerData.businessName },
          headers,
        )
        .subscribe({
          error: () => setError(t("auth.errors.business")),
          complete: () => setLoadingProp("business", false),
        });
    }
    apiDriver
      .put(
        `${config.api.orders}${i18n.resolvedLanguage}/ShippingAddresses/`,
        registerData.shippingAddress,
        headers,
      )
      .subscribe({
        error: () => setError(t("auth.errors.shippingAddress")),
        complete: () => setLoadingProp("shippingAddress", false),
      });
    apiDriver
      .put(
        `${config.api.orders}${i18n.resolvedLanguage}/BillingInfos/`,
        registerData.billingInfo,
        headers,
      )
      .subscribe({
        error: () => setError(t("auth.errors.billingInfo")),
        complete: () => setLoadingProp("billingInfo", false),
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    signUp();
    return false;
  };

  const onChangePasswordScore = (score) => {
    onChangeRegisterData({ target: { name: "passwordScore", value: score } });
  };

  const validate = () => {
    if (registerData.user.givenName.length < 3) {
      setError(t("auth.validation.givenName"));
      return false;
    }
    if (registerData.user.surname.length < 3) {
      setError(t("auth.validation.surname"));
      return false;
    }
    if (registerData.user.email.length < 6) {
      setError(t("auth.validation.email"));
      return false;
    }
    if (registerData.user.password.length < 3) {
      setError(t("auth.validation.password"));
      return false;
    }
    if (registerData.user.repeatPassword.length < 3) {
      setError(t("auth.validation.repeatPassword"));
      return false;
    }
    if (registerData.user.password !== registerData.user.repeatPassword) {
      setError(t("auth.validation.passwordsNotThatSame"));
      return false;
    }
    if (isBusiness) {
      if (registerData.businessName.length < 3) {
        setError(t("auth.validation.businessName"));
        return false;
      }
    }
    if (registerData.passwordScore < 2) {
      setError(t("auth.validation.passwordToWeak"));
      return false;
    }
    if (registerData.user.password !== registerData.user.repeatPassword) {
      setError(t("auth.validation.passwordsNotThatSame"));
      return false;
    }

    const isBillingInfoValid = (
      registerData.billingInfo.line1.length > 2 &&
      registerData.billingInfo.city.length > 2 &&
      registerData.billingInfo.postalCode.length > 2 &&
      registerData.billingInfo.countryCode.length === 2 &&
      registerData.billingInfo.buyerName.length > 2 &&
      ((isBusiness && registerData.buyerName && registerData.vatID) ||
        !isBusiness)
    );
    const isShippingAddressValid = (
      registerData.shippingAddress.line1.length > 2 &&
      registerData.shippingAddress.city.length > 2 &&
      registerData.shippingAddress.postalCode.length > 2 &&
      registerData.shippingAddress.countryCode.length === 2
    );

    if (isBillingInfoValid) {
      setError(t("auth.errors.billingInfo"));
      return false;
    }

    if (isShippingAddressValid) {
      setError(t("auth.errors.shippingAddress"));
      return false;
    }

    setError("");
    return true;
  };

  const getFromGus = (vatID) => {
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/BillingInfos/Gus/${vatID}`,
      )
      .subscribe((response) => {
        if (response.status === 200) {
          setRegisterData({
            ...registerData,
            businessName: response.response.buyerName,
            shippingAddress: response.response,
            billingInfo: response.response,
          });
        } else {
          setError(t("auth.validation.invalidVatId"));
        }
      });
  };

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>{t("auth.register.registerTitle")} - Printweb.pl</title>
      </Helmet>
      <div className="bg-dark py-5">
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col className="px-5 mt-5 pt-5">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-white py-4">
                    <CardTitle className="text-center" tag="h4">
                      {t("auth.register.registerTitle")}
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    {error && (
                      <Alert color="danger">
                        <p>{error}</p>
                      </Alert>
                    )}
                    <Form onSubmit={onSubmit}>
                      <h3>{t('users.accountType.title')}</h3>
                      <Row className="my-5">
                        <Col md="6">
                          <Button type="button" color={!isBusiness ? "primary" : "secondar"} onClick={() => setIsBusiness(false)} block>
                            {t('users.accountType.private')}
                          </Button>
                        </Col>
                        <Col md="6">
                          <Button type="button" color={isBusiness ? "primary" : "secondar"} onClick={() => setIsBusiness(true)} block>
                            {t('users.accountType.business')}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          &nbsp;
                        </Col>
                        <Col md="6">
                          {isBusiness ? (
                            <div>
                              <Alert className="mb-5">{t('users.fillByVatNumber')}</Alert>
                              <FormGroup className={"mb-5 " + (focus === "vat" ? "focused" : "")}>
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="fas fa-building"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder={t("customers.fields.vatID")}
                                    type="text"
                                    name="businessName"
                                    value={vat}
                                    onChange={(e) => setVat(e.target.value)}
                                    onFocus={() => setFocus("vat")}
                                    minLength={3}
                                    maxLength={48}
                                  ></Input>
                                  <InputGroupAddon addonType="append">
                                    <Button onClick={() => getFromGus(vat)}>
                                      <i className="fas fa-download"></i>
                                    </Button>
                                  </InputGroupAddon>
                                </InputGroup>
                              </FormGroup>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                        </Col>
                        <Col md="3">
                          &nbsp;
                        </Col>
                      </Row>
                      <h3 className="my-5">Dane do konta</h3>
                      <Row className="my-5">
                        <Col md="6" className="text-left">
                          <Row className="mb-3">
                            <Col lg="6" className="pr-md-0">
                              <FormGroup className={"mb-3 " + (focus === "name" ? "focused" : "")}>
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="fas fa-user"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder={t("users.fields.givenName")}
                                    type="text"
                                    name="user.givenName"
                                    value={registerData.user.givenName}
                                    onChange={onChangeRegisterData}
                                    onFocus={() => setFocus("name")}
                                    required={true}
                                    minLength={3}
                                    maxLength={48}
                                    pattern="[^0-9]*"
                                  ></Input>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col lg="6" className="pl-md-0">
                              <FormGroup className={"mb-3 " + (focus === "surname" ? "focused" : "")}>
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="fas fa-user"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder={t("users.fields.surname")}
                                    type="text"
                                    name="user.surname"
                                    value={registerData.user.surname}
                                    onChange={onChangeRegisterData}
                                    onFocus={() => setFocus("surname")}
                                    required={true}
                                    minLength={3}
                                    maxLength={48}
                                    pattern="[^0-9]*"
                                  ></Input>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                          {isBusiness ? (
                            <FormGroup className={"mb-3 " + (focus === "businessName" ? "focused" : "")}>
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-building"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder={t("customers.fields.businessName")}
                                  type="text"
                                  name="businessName"
                                  value={registerData.businessName}
                                  onChange={onChangeRegisterData}
                                  onFocus={() => setFocus("businessName")}
                                  required={isBusiness}
                                  minLength={3}
                                  maxLength={48}
                                ></Input>
                              </InputGroup>
                            </FormGroup>
                          ) : (
                            <React.Fragment />
                          )}
                          <FormGroup className={"mb-3 " + (focus === "email" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("users.fields.email")}
                                type="email"
                                name="user.email"
                                value={registerData.user.email}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("email")}
                                required={true}
                                minLength={3}
                                maxLength={48}
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$"
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className={focus === "password" ? "focused" : ""}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-lock-circle-open"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("users.fields.password")}
                                type="password"
                                name="user.password"
                                value={registerData.user.password}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("password")}
                                required={true}
                                minLength={8}
                              ></Input>
                            </InputGroup>
                            <div className={focus === "password" ? "d-block" : "d-none"}>
                              <PasswordStrengthBar
                                password={registerData.user.password}
                                scoreWords={[
                                  t("users.password.weak"),
                                  t("users.password.weak"),
                                  t("users.password.enough"),
                                  t("users.password.strong"),
                                  t("users.password.veryStrong"),
                                ]}
                                shortScoreWord={t("users.password.tooShort")}
                                minLength={8}
                                onChangeScore={onChangePasswordScore}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup
                            className={`${registerData.user.password && registerData.user.repeatPassword
                              ? registerData.user.password ===
                                registerData.user.repeatPassword
                                ? "has-success"
                                : "has-danger"
                              : ""
                              } ${focus === "repeatPassword" ? "focused" : ""}`}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-lock-circle-open"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("users.fields.repeatPassword")}
                                type="password"
                                name="user.repeatPassword"
                                value={registerData.user.repeatPassword}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("repeatPassword")}
                                required={true}
                                minLength={8}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <h3 className="my-5">Dane do wysyłki</h3>
                      <Row className="my-5">
                        <Col md="6">
                          <FormGroup className={"mb-3 " + (focus === "countryCode" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-map-marker"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <CountrySelector
                                name="shippingAddress.countryCode"
                                value={registerData.shippingAddress.countryCode}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("countryCode")}
                                required={true}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className={"mb-3 " + (focus === "address" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-map"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("customers.shippingAddress.line1")}
                                type="text"
                                name="shippingAddress.line1"
                                value={registerData.shippingAddress.line1}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("address")}
                                required={true}
                                minLength={4}
                                maxLength={48}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className={"mb-3 " + (focus === "extra" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-map"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("customers.shippingAddress.line2")}
                                type="text"
                                name="shippingAddress.line2"
                                value={registerData.shippingAddress.line2}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("extra")}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                          <Row>
                            <Col md="4" className="pr-md-0">
                              <FormGroup className={"mb-3 " + (focus === "postalCode" ? "focused" : "")}>
                                <InputGroup className="input-group-alternative">
                                  <PostalCodeInput
                                    countryCode={registerData.shippingAddress.countryCode}
                                    placeholder={t("customers.shippingAddress.postalCode")}
                                    type="text"
                                    name="shippingAddress.postalCode"
                                    value={registerData.shippingAddress.postalCode}
                                    onChange={onChangeRegisterData}
                                    onFocus={() => setFocus("postalCode")}
                                    required={true}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col md="8" className="pl-md-0">
                              <FormGroup className={"mb-3 " + (focus === "city" ? "focused" : "")}>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    placeholder={t("customers.shippingAddress.city")}
                                    type="text"
                                    name="shippingAddress.city"
                                    value={registerData.shippingAddress.city}
                                    onChange={onChangeRegisterData}
                                    onFocus={() => setFocus("city")}
                                    required={true}
                                    minLength={3}
                                    maxLength={48}
                                  ></Input>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <FormGroup className={"mb-3 " + (focus === "person" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-user"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("customers.shippingAddress.person")}
                                type="text"
                                name="shippingAddress.person"
                                value={registerData.shippingAddress.person}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("person")}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className={"mb-3 " + (focus === "email" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-envelope"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("customers.shippingAddress.email")}
                                type="email"
                                name="shippingAddress.email"
                                value={registerData.shippingAddress.email}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("email")}
                                required={true}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className={"mb-3 " + (focus === "phone" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-phone"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <InputGroupAddon>
                                <InputGroupText>
                                  {Countries().find(
                                    (c) => c.code === registerData.shippingAddress.countryCode,
                                  )?.phonePrefix || <span>&nbsp;</span>}
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("customers.shippingAddress.phone")}
                                type="text"
                                name="shippingAddress.phone"
                                value={registerData.shippingAddress.phone}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("phone")}
                                pattern={
                                  Countries().find(
                                    (c) => c.code === registerData.shippingAddress.countryCode,
                                  )?.phonePattern || ".*"
                                }
                                required={true}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <h3 className="my-5">Dane do faktury</h3>
                      <Row className="my-5">
                        <Col md="6">
                          <FormGroup className={"mb-3 " + (focus === "countryCode" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-map-marker"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <CountrySelector
                                name="billingInfo.countryCode"
                                value={registerData.billingInfo.countryCode}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("countryCode")}
                                required={true}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className={"mb-3 " + (focus === "address" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-map"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("customers.shippingAddress.line1")}
                                type="text"
                                name="billingInfo.line1"
                                value={registerData.billingInfo.line1}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("address")}
                                required={true}
                                minLength={4}
                                maxLength={48}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className={"mb-3 " + (focus === "extra" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-map"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("customers.shippingAddress.line2")}
                                type="text"
                                name="billingInfo.line2"
                                value={registerData.billingInfo.line2}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("extra")}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                          <Row>
                            <Col md="4" className="pr-md-0">
                              <FormGroup className={"mb-3 " + (focus === "postalCode" ? "focused" : "")}>
                                <InputGroup className="input-group-alternative">
                                  <PostalCodeInput
                                    countryCode={registerData.billingInfo.countryCode}
                                    placeholder={t("customers.shippingAddress.postalCode")}
                                    type="text"
                                    name="billingInfo.postalCode"
                                    value={registerData.billingInfo.postalCode}
                                    onChange={onChangeRegisterData}
                                    onFocus={() => setFocus("postalCode")}
                                    required={true}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col md="8" className="pl-md-0">
                              <FormGroup className={"mb-3 " + (focus === "city" ? "focused" : "")}>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    placeholder={t("customers.shippingAddress.city")}
                                    type="city"
                                    name="billingInfo.city"
                                    value={registerData.billingInfo.city}
                                    onChange={onChangeRegisterData}
                                    onFocus={() => setFocus("city")}
                                    required={true}
                                    minLength={3}
                                    maxLength={48}
                                  ></Input>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          {
                            isBusiness &&
                            <>
                              <FormGroup className={"mb-3 " + (focus === "businessName" ? "focused" : "")}>
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="fas fa-building"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder={t("customers.fields.businessName")}
                                    type="text"
                                    name="billingInfo.buyerName"
                                    value={registerData.billingInfo.buyerName}
                                    onChange={onChangeRegisterData}
                                    onFocus={() => setFocus("businessName")}
                                    required={isBusiness}
                                    minLength={3}
                                    maxLength={48}
                                  ></Input>
                                </InputGroup>
                              </FormGroup>
                              <FormGroup className={"mb-3 " + (focus === "vatNumber" ? "focused" : "")}>
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="fas fa-coins"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <VatIDInput
                                    countryCode={registerData.billingInfo.countryCode}
                                    placeholder={t("customers.billingInfo.vatID")}
                                    type="text"
                                    name="billingInfo.vatID"
                                    value={registerData.billingInfo.vatID}
                                    onChange={onChangeRegisterData}
                                    onFocus={() => setFocus("vatNumber")}
                                    required={isBusiness}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </>
                          }
                          <FormGroup className={"mb-3 " + (focus === "email" ? "focused" : "")}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-envelope"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder={t("customers.billingInfo.email")}
                                type="email"
                                name="billingInfo.email"
                                value={registerData.billingInfo.email}
                                onChange={onChangeRegisterData}
                                onFocus={() => setFocus("email")}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="text-right">
                        <Button
                          className="my-4"
                          color="primary"
                          type="submit"
                          disabled={loading?.length}
                        >
                          {t("auth.register.do")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default withRouter(Register);
